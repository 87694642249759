<template>
<html lang="en" class="notranslate" translate="no"><!-- All translators -->
      <head><meta name="google" content="notranslate" /> <!-- Just for google -->
    </head>
  <div class="app">
    <a name="validaciones"></a>

    <Menu :list="menuList"/>
    <RegistrationPersonal />
    <Footer />

    <!-- <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">
    <link href="https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css" rel="stylesheet"> -->
  </div>
</html>

</template>


<script>

import Menu from "@/components/Menu.vue";
import Footer from '@/components/Footer3.vue'
import RegistrationPersonal from '@/components/Staff/RegistrationPersonal.vue';

import { ref, onBeforeMount } from 'vue';
import menuOptions  from "@/helpers/menu.js";

export default {
  setup() {
    let menuList = ref([]);

    onBeforeMount(async () => {
      await getMenuOptionsList();
    });

    const getMenuOptionsList = async () => {
      await menuOptions.getMenuOptions().then(resp => {
        menuList.value = resp.data.FONCABSA;
      }).catch(err => {
        console.log("🚀 ~ file: Home.vue:223 ~ getMenuOptions ~ err", err);
      });
    };

    return {
      menuList
    };
  },
  name: "Home",
  components: {
    Menu,
    Footer,
    RegistrationPersonal,
  }
}
</script>



