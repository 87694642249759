<template>
<html lang="en" class="notranslate" translate="no"><!-- All translators -->
      <head><meta name="google" content="notranslate" /> <!-- Just for google -->
    </head>
  <div class="app">
    <a name="validaciones"></a>
    
    <body>
        <div class="container mx-auto">
          <div class="row">
            <Loading :isLoading="this.isLoading" />
            <div class="flex-1 bg-gray-1 px-0 py-0 m-0">
              <div
                class="flex bg-gray-000 h-34 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lx:grid-cols-2;"
              >
                <div class="center collapse dont-collapse-sm">
                  <img
                    src="@/assets/imagen-registro.png"
                    class="img-responsive"
                    style="width: 580px; height: 855px"
                  />
                </div>
                <div class="center">
                  <div class="col-xs-1"></div>
                  <div class="col-xs-10">
                    <center>
                      <p class="title" style="margin-top:10px;text-align: left;margin-left: 20px">
                        Crear cuenta para personal
                      </p>
                      <p style="text-align: left; margin-left: 20px;font-family:Roboto;font-weight:400;font-size:18px;color:#726659">
                        Ingrese sus datos y comience a
                        formar parte de FONCABSA
                      </p>
                    </center>
                    <form
                      @submit="registrar"
                      class="px-8 pt-6 pb-8 mb-4"
                      style="text-align: left"
                    >
                      <!--display: inline-block;-->

                      <center>
                        <!-- <p v-if="errors.length">
                                        <ul>
                                            <li v-for="error in errors"><h5 style="color:red">{{ error }}</h5></li>
                                        </ul>
                                        </p> -->
                        <br />
                      </center>
                      <div class="form-group" v-show="false">
                        <label to="" class="label-input" style="text-align: left;color: #8e857a; margin-bottom:4px;">C&oacute;digo Postal:</label>
                        <input
                          type="number"
                          @keyup="validatePostalCode($event)"
                          class="shadow-md border-2 rounded-lg w-full px-2 py-2 "
                          id="Input_PostalCode"
                          onKeyPress="if(this.value.length==5) return false;"
                          v-model="postalCode"
                        />
                      </div>
                      <div class="form-group" v-show="false">
                        <label to="Select_StateRegister" class="label-input" style="text-align: left;color: #8e857a; margin-bottom:4px;margin-top:20px">Estado</label>
                        <select
                          class="shadow-md border-2 rounded-lg w-full px-2 py-2 "
                          id="Select_StateRegister"
                          ref="stateSelected"
                          
                          v-model="state"
                          @change="changeValueState()"
                          :disabled="postalCodeFound"
                          style="border: 1px solid #a75e7a; "
                        >
                          <option
                            v-for="(state, i) in list_states"
                            :key="i"
                            v-bind:value="addUpperCase(state.nombre)"
                          >
                            {{ addUpperCase(state.nombre) }}
                          </option>
                        </select>
                      </div>
                      <div class="mt-5" v-show="false">
                        <label to="Select_MunicipalityRegister" class="label-input" style="text-align: left;color: #8e857a; margin-bottom:4px;margin-top:20px">Municipio</label>
                        <select
                          lang="en"
                          translate="no"
                          class="form-control notranslate inputs-form"
                          id="Select_MunicipalityRegister"
                          ref="citySelected"
                          
                          v-model="municipality"
                          @change="changeValueMunicipality()"
                          :disabled="postalCodeFound"
                          style="border: 1px solid #a75e7a; "
                        >
                          <option
                            v-for="(municipality, i) in list_municipalities"
                            :key="i"
                            v-bind:value="addUpperCase(municipality.nombre)"
                          >
                            {{ addUpperCase(municipality.nombre) }}
                          </option>
                        </select>
                      </div>
                      <div class="mt-5" v-show="false">
                        <label to="Select_CityRegister" class="label-input" style="text-align: left;color: #8e857a; margin-bottom:4px;margin-top:20px">Ciudad</label>
                        <select
                          lang="en"
                          translate="no"
                          class="form-control notranslate inputs-form"
                          id="Select_CityRegister"
                          ref="citySelected"
                          v-model="city"
                          :disabled="postalCodeFound"
                          style="border: 1px solid #a75e7a; "
                        >
                          <option
                            v-for="(city, i) in list_cities"
                            :key="i"
                            v-bind:value="addUpperCase(city.nombre)"
                          >
                            {{ addUpperCase(city.nombre) }}
                          </option>
                        </select>
                      </div>
                      <!--<div class="mt-5">
                        <label to="" class="label-input" style="text-align: left;color: #8e857a; margin-bottom:4px;margin-top:20px">RFC</label>
                        <input
                          type="text"
                          class="shadow-md border-2 rounded-lg w-full px-2 py-2 "
                          id="Input_RfcClient"
                          minlength="13"
                          maxlength="13"
                          v-on:change="validateRfc()"
                          @keyup="validateMaxLength('Input_RfcClient', 13)"
                          v-model="rfc"
                          required
                        />
                      </div>-->
                      <div class="mt-5">
                        <label to="Input_PaternalClient" class="label-input" style="text-align: left;color: #8e857a; margin-bottom:4px;margin-top:20px">Apellido paterno</label>
                        <input
                          type="text"
                          class="shadow-md border-2 rounded-lg w-full px-2 py-2 "
                          id="Input_PaternalClient"
                          minlength="2"
                          maxlength="30"
                          v-model="paternal"
                          pattern="[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]{2,30}"
                          ref="paterno"
                        />
                      </div>
                      <div class="mt-5">
                        <label to="Input_MaternalClient" class="label-input" style="text-align: left;color: #8e857a; margin-bottom:4px;margin-top:20px">Apellido materno</label>
                        <input
                          type="text"
                          class="shadow-md border-2 rounded-lg w-full px-2 py-2 "
                          id="Input_MaternalClient"
                          minlength="2"
                          maxlength="30"
                          v-model="maternal"
                          ref="materno"
                          pattern="[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]{2,30}"

                        />
                      </div>
                      <div class="mt-5">
                        <label to="Input_FirstNamesClient" class="label-input" style="text-align: left;color: #8e857a; margin-bottom:4px;margin-top:20px">Primer nombre</label>
                        <input
                          type="text"
                          class="shadow-md border-2 rounded-lg w-full px-2 py-2 "
                          id="Input_FirstNamesClient"
                          minlength="3"
                          maxlength="30"
                          v-model="fisrt_name"
                          pattern="[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]{1,30}"
                          required
                        />
                      </div>
                      <div class="mt-5">
                        <label to="Input_SecondNamesClient" class="label-input" style="text-align: left;color: #8e857a; margin-bottom:4px;margin-top:20px">Segundo nombre</label>
                        <input
                          type="text"
                          class="shadow-md border-2 rounded-lg w-full px-2 py-2 "
                          id="Input_SecondNamesClient"
                          minlength="3"
                          maxlength="30"
                          v-model="last_name"
                          pattern="[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]{1,30}"
                        />
                      </div>
                      <!--<div class="mt-5">
                        <label
                          style="
                            text-align: left;
                            font-size: 15px;
                            color: black;
                            color: #6e6e6e;
                          "
                          >Celular:</label
                        >
                        <input
                          type="number"
                          oninput="validity.valid||(value='');"
                          onKeyPress="if(this.value.length==10) return false;"
                          style="
                            border: 1px solid #a75e7a;
                            text-transform: capitalize;
                            ;
                          "
                          class="form-control"
                          id="inputCellPhoneClient"
                          minlength="10"
                          maxlength="10"
                          v-model="cellphone"
                          required
                        />
                      </div>-->
                      <div class="mt-5">
                        <label to="Input_EmailClient" class="label-input" style="text-align: left;color: #8e857a; margin-bottom:4px;margin-top:20px">Correo electrónico</label>
                        <input
                          type="text"
                          @blur="validateEmail(email)"
                          class="shadow-md border-2 rounded-lg w-full px-2 py-2 "
                          id="Input_EmailClient"
                          @keyup="validateWhiteSpaceEmail()"
                          minlength="10"
                          v-model="email"
                          pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                          required
                        />
                      </div>
                      <div class="mt-5">
                        <label to="Input_Password" class="label-input mb-2" style="text-align: left;color: #8e857a; margin-bottom:4px;margin-top:20px">Contraseña</label>
                        <div class="input-group" style="">
                          <input
                            type="password"
                            class="shadow-md border-2 rounded-lg py-3 w-full px-3"
                            id="Input_Password"
                            minlength="8"
                            maxlength="30"
                            v-model="password"
                            required
                          />
                          <span
                            tabIndex="-1"
                            class="input-group-addon"
                            style="position:absolute;margin-left: -40px;margin-top: 15px;"
                            ><button
                              type="button"
                              tabIndex="-1"
                              v-on:click="changeIcoPassword()"
                            >
                              <div tabIndex="-1" id="divImageHidePasswordOn">
                                <img
                                  id="imageHidePasswordOn"
                                  src="@/assets/visibility-off.svg"
                                  width="20"
                                  height="20"
                                  style="margin-right: 18px"
                                />
                              </div>
                              <div
                                tabIndex="-1"
                                id="divImageHidePasswordOff"
                                style="visibility: collapse"
                              >
                                <img
                                  tabIndex="-1"
                                  id="imageHidePasswordOff"
                                  src="@/assets/visibility-on.svg"
                                  width="0"
                                  height="0"
                                  style="margin-right: 18px"
                                />
                              </div>
                            </button>
                          </span>
                        </div>
                      </div>
                      <div class="mt-5">
                        <label to="Input_PasswordConfirm" class="label-input mb-2" style="text-align: left;color: #8e857a; margin-bottom:4px;margin-top:20px">Confirmar contraseña</label>
                        <div class="form-group" style="">
                          <input
                            type="password"
                            class="shadow-md border-2 rounded-lg py-3 w-full px-3"
                            id="Input_PasswordConfirm"
                            minlength="8"
                            maxlength="30"
                            v-model="confirmPassword"
                            required
                          />
                          <span
                            tabIndex="-1"
                            class="input-group-addon"
                            style="position:absolute;margin-left: -40px;margin-top: 15px;"
                            ><button
                              type="button"
                              tabIndex="-1"
                              v-on:click="changeIcoPasswordConfirm()"
                            >
                              <div
                                tabIndex="-1"
                                id="divImageHidePasswordConfirmOn"
                              >
                                <img
                                  id="imageHidePasswordConfirmOn"
                                  src="@/assets/visibility-off.svg"
                                  width="20"
                                  height="20"
                                  style="margin-right: 18px"
                                />
                              </div>
                              <div
                                tabIndex="-1"
                                id="divImageHidePasswordConfirmOff"
                                style="visibility: collapse"
                              >
                                <img
                                  tabIndex="-1"
                                  id="imageHidePasswordConfirmOff"
                                  src="@/assets/visibility-on.svg"
                                  width="0"
                                  height="0"
                                  style="margin-right: 18px"
                                />
                              </div></button
                          ></span>
                        </div>
                      </div>
                      <center class="mt-5">
                        <button id="RegisterButton" class="btn-submit">
                          Registro
                        </button>
                      </center>
                    </form>
                    <br />
                  </div>
                  <div class="col-xs-1"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
      <!-- <button v-on:click="changeIcoPassword()"><img id="imageHidePasswordConfirm" src="../assets/visibility-off.png" width="20" height="20"></button> -->

    <!-- <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">
    <link href="https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css" rel="stylesheet"> -->

  </div>
  
</html>
</template>


<script>
import('@/assets/css/stylesheet.css')

import Referral from '@/classes/Referral'
import Loading from "@/components/Loading/VueLoading.vue";
import Swal from "sweetalert2";
import { setBirthday, validateMatchRFCwithName, validityRFC, referenceIsAvailable} from "@/api/user";
export default {
  data() {
    return {
      errors: [],
      apiToken: null,
      rfc: "",
      paternal: "",
      maternal: "",
      fisrt_name: "",
      last_name: "",
      cellphone: "",
      password: "",
      confirmPassword: "",
      email: "",
      state: null,
      city: null,
      municipality: null,
      postalCode: "",
      confirmPasswordHide: false,
      passwordHide: false,
      list_states: null,
      list_cities: null,
      list_municipalities: null,
      postalCodeFound: false,
      seRegistroCliente: false,
      ccapi: process.env.VUE_APP_CCAPI,
      multiApi: process.env.VUE_APP_MULTIAPI,
      isLoading: false,
      reference: "",
      typeReference: "",
      isErrorRFC:null,
      isValidateLastname: false,
      isDataCorrect: false,
      birthday:null,
      isMatchRFCwithName: false,
      app_urlweb: process.env.VUE_APP_URLWEB,
      referalId:"",
      referralObjectClass: null,
      name_referece: ""
    };
  },
  name: "Home",
  components: {
    Loading,
  },
  async mounted() {
    this.referralObjectClass = new Referral();
    
    document
      .getElementById("Input_PaternalClient")
      .addEventListener("keyup", ({ detail }) => {
        var regex = /^[a-zA-ZÀ-ÿ]+$/;

        if (regex.test(this.paternal) != true) {
          this.paternal = this.paternal.replace(/[^a-zA-ZÀ-ÿ@\s]+/, "");
        }
      });

    document
      .getElementById("Input_MaternalClient")
      .addEventListener("keyup", ({ detail }) => {
        var regex = /^[a-zA-ZÀ-ÿ]+$/;

        if (regex.test(this.maternal) != true) {
          this.maternal = this.maternal.replace(/[^a-zA-ZÀ-ÿ@\s]+/, "");
        }
      });

    document
      .getElementById("Input_FirstNamesClient")
      .addEventListener("keyup", ({ detail }) => {
        var regex = /^[a-zA-ZÀ-ÿ]+$/;

        if (regex.test(this.fisrt_name) != true) {
          this.fisrt_name = this.fisrt_name.replace(/[^a-zA-ZÀ-ÿ@\s]+/, "");
        }
      });

    document
      .getElementById("Input_SecondNamesClient")
      .addEventListener("keyup", ({ detail }) => {
        var regex = /^[a-zA-ZÀ-ÿ]+$/;

        if (regex.test(this.last_name) != true) {
          this.last_name = this.last_name.replace(/[^a-zA-ZÀ-ÿ@\s]+/, "");
        }
      });

    this.reference  = this.getParameterByName("reference");
    console.log("referencia:" + this.reference);

    this.referenceIsAvailable()
  },
  async beforeMount() {
  },
  watch: {
    state() {
      if (this.state == null) {
        return;
      }
      this.errors = [];
      this.axios
        .post(
          this.multiApi + "/Api/Catalogo/Sepomex/obtener_municipios_por_estado",
          {
            contenido: {
              estado: this.state,
            },
            withCredentials: true,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          },
          {
            auth: {
              username: "multicr",
              password: "*cabsa2018*",
            },
          }
        )
        .then((response) => {
          var cities = [];
          this.list_municipalities = response.data.municipios;
        });
    },
    municipality () {
      this.axios.post(this.multiApi +"/Api/Catalogo/Sepomex/obtener_ciudades_por_estado_y_municipio", {
          contenido: {
              estado: this.state,
              municipio: this.municipality
          },
          withCredentials: true,
          headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          }
      },{
          auth: {
          username: "multicr",
          password: "*cabsa2018*"
      }}
      ).then(response => {
          this.list_cities = response.data.ciudades
      }).catch((error) => {
          
      });
    },
    postalCode () {
      this.errors = [];
    },
    city() {
      this.errors = [];
    },
    rfc() {
      this.errors = [];
    },
    paternal() {
      this.errors = [];
    },
    maternal() {
      this.errors = [];
    },
    fisrt_name() {
      this.errors = [];
    },
    last_name() {
      this.errors = [];
    },
    cellphone() {
      this.errors = [];
    },
    email() {
      this.errors = [];
    },
    password() {
      this.errors = [];
    },
    confirmPassword() {
      this.errors = [];
    },
  },
  methods: {
    async validateMaxLength(name, maxLength){
      var input = document.getElementById(name);
      if(input.value.length > maxLength){
        input.value = input.value.substring(0,maxLength)
      }
    },
    async referenceIsAvailable(){
      if(this.reference == ""){
        Swal.fire({
          title: "No se encontro referencia",
          text: "Para registrarse necesita ser invitado por un administrador",
          confirmButtonColor: '#FEB72B',
        }).then((result) => {
          this.$router.push({path:"/"});
        })
      }

      var resp = false;
      await referenceIsAvailable(
        this.reference
      ).then((response) => {
        resp = !!response.data.response.available
        this.name_referece = response.data.response.name_referece
        if(!resp){
          Swal.fire({
            title: "Referencia inválida",
            text: "Para registrarse necesita ser invitado por un administrador",
            confirmButtonColor: '#FEB72B',
          }).then((result) => {
            // window.location.href = "/"
            this.$router.push({path:"/"});
          })
        }else{
          if(this.name_referece == ""){
            Swal.fire({
              title: "Referencia inválida",
              text: "Para registrarse necesita ser invitado por un administrador",
              confirmButtonColor: '#FEB72B',
            }).then((result) => {
              // window.location.href = "/"
              this.$router.push({path:"/"});
            })
          }else{
            this.checkRegistrationPage(this.name_referece, this.reference)
          }
        }
      }).catch((error) => {
      }).finally(() => {
      })
    },
    async validateRfc(){
      var resultRfc = false
      if(this.rfc.length == 13){
        await validityRFC(
          this.rfc
        )
        .then((response) => {
          resultRfc = !!response.data.response.rfc_is_valid
        })
        .catch((error) => {
          conosle.log(error)
          console.log("Error al validar el RFC: ", error);
          Swal.fire({
            title: "Aviso",
            icon: "error",
            html:error.response.data.errors.length > 0 ? error.errors.map(msj => msj) : error.response.data.response.message,
            confirmButtonColor: '#FEB72B',
          });
        });
        
        return resultRfc
      }
    },
    focusInputPaterno() {
      this.$refs.paterno.focus();
    },
    focusInputMaterno() {
      this.$refs.materno.focus();
    },
    getErrors(errors) {
      let messageError = "";
      for (const key in errors) {
        let error = errors[key];
        messageError += `${this.transformNameFields(error)} <br/>`;
      }

      return messageError;
    },
    transformNameFields(field){
      let nameField = "";
      switch (field){
        case "name":
          nameField = "Primer nombre";
          break;
        case "middle_name":
          nameField = "Segundo nombre";
          break;
        case "paternal_name":
          nameField = "Apellido paterno";
          break;
        case "maternal_name":
          nameField = "Apellido materno";
          break;
        case "email":
          nameField = "Correo";
          break;
        default:
          nameField = field;
          break;
      }
      return this.capitalizeTxt(nameField);
    },
    transformMessages(error){
      let descriptionError ="";
      switch (error){
        case "ya existe":
          descriptionError = "ya registrado, favor de rectificar.";
          break;
        default:
          descriptionError = error;
          break;
      }
      return descriptionError;
    },
    addUpperCase(value) {
      if (value != "") {
        value = value.toUpperCase();
      }
      return value;
    },
    validateEmail(email) {
      var regex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
      if (regex.test(email)) {
        return true;
      } else {
        return false;
      }
      return false;
    },
    async validateWhiteSpaceEmail(){
      var regex = /\s/g;

      this.email = this.email.replaceAll(regex, "");
    },
    changeValueState() {
      this.municipality = "";
      this.city = "";
      this.list_cities = [];
    },
    changeValueMunicipality() {
      this.city = "";
    },
    changeIcoPassword() {
      if (this.passwordHide) {
        document
          .getElementById("Input_Password")
          .setAttribute("type", "password");
        document.getElementById("divImageHidePasswordOff").style.visibility =
          "collapse";
        document.getElementById("imageHidePasswordOff").width = "0";
        document.getElementById("imageHidePasswordOff").height = "0";
        document.getElementById("divImageHidePasswordOn").style.visibility =
          "visible";
        document.getElementById("imageHidePasswordOn").width = "20";
        document.getElementById("imageHidePasswordOn").height = "20";
        this.passwordHide = false;
      } else {
        document.getElementById("Input_Password").setAttribute("type", "text");
        document.getElementById("divImageHidePasswordOn").style.visibility =
          "collapse";
        document.getElementById("imageHidePasswordOn").width = "0";
        document.getElementById("imageHidePasswordOn").height = "0";
        document.getElementById("divImageHidePasswordOff").style.visibility =
          "visible";
        document.getElementById("imageHidePasswordOff").width = "20";
        document.getElementById("imageHidePasswordOff").height = "20";
        this.passwordHide = true;
      }
    },
    changeIcoPasswordConfirm() {
      if (this.confirmPasswordHide) {
        document
          .getElementById("Input_PasswordConfirm")
          .setAttribute("type", "password");
        document.getElementById(
          "divImageHidePasswordConfirmOff"
        ).style.visibility = "collapse";
        document.getElementById("imageHidePasswordConfirmOff").width = "0";
        document.getElementById("imageHidePasswordConfirmOff").height = "0";
        document.getElementById(
          "divImageHidePasswordConfirmOn"
        ).style.visibility = "visible";
        document.getElementById("imageHidePasswordConfirmOn").width = "20";
        document.getElementById("imageHidePasswordConfirmOn").height = "20";
        this.confirmPasswordHide = false;
      } else {
        document
          .getElementById("Input_PasswordConfirm")
          .setAttribute("type", "text");
        document.getElementById(
          "divImageHidePasswordConfirmOn"
        ).style.visibility = "collapse";
        document.getElementById("imageHidePasswordConfirmOn").width = "0";
        document.getElementById("imageHidePasswordConfirmOn").height = "0";
        document.getElementById(
          "divImageHidePasswordConfirmOff"
        ).style.visibility = "visible";
        document.getElementById("imageHidePasswordConfirmOff").width = "20";
        document.getElementById("imageHidePasswordConfirmOff").height = "20";
        this.confirmPasswordHide = true;
      }
    },
    getParameterByName(name) {
      name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
      var regexS = "[\\?&]" + name + "=([^&#]*)";
      var regex = new RegExp(regexS);
      var results = regex.exec(window.location.href);
      if(results == null){return ""}
      return decodeURIComponent(results[1].replace(/\+/g, " "));
    },
    capitalizeTxt(txt) {
      return txt.charAt(0).toUpperCase() + txt.slice(1);
    },
    async registrar(e) {
      e.preventDefault();
      
      this.isLoading = true;
      let procesar = await this.validateFilds();
      if (this.errors.length == 0 ) {
        if (procesar) {
          let data = {
            user: {
              email: this.email.toLowerCase().trim(),
              password: this.password,
              personnel_info_attributes: {
                0: {
                  paternal_name: this.paternal.trim(),
                  maternal_name: this.maternal.trim(),
                  name: this.fisrt_name.trim(),
                  middle_name: this.last_name.trim(),
                  status: "activo"
                },
              },
            },
            url: this.app_urlweb+"/personal/login?reference=",
            reference:""
          };
          console.log("referencia: ",this.reference);

          if (this.reference != "") {
            data.reference = this.reference;
          }

          await this.axios.post(this.ccapi + `/${process.env.VUE_APP_BUSINESS_FONCABSA}/personnel/signup`,
          data,
          {
            "Content-Type": "application/json",
          }).then((response) => {
            this.isLoading = false;
            Swal.fire({
              title: "Aviso",
              text:
                  "Se registró correctamente. Para activar su cuenta deberá de entrar a la liga que se le envió al correo proporcionado, si no lo encuentra favor de buscar en correo no deseado.",
              icon: "success",
              confirmButtonColor: '#FEB72B',
            }).then(function () {
              // window.location.href = "/login";
              this.$router.push({path:"/login"});
            });
          }).catch((error) => {
            console.log("Se presentó un error al registrar: ",error.response.data);
            this.isLoading = false;
            
            let messageError = ""
            if(Array.isArray(error.response.data.errores) && error.response.data.errores[0] == "No se encontro referencia para poder registrar usuario"){
              messageError = "La referencia ya está en uso"
            }else{
              this.errors = error.response.data.errores;
              messageError = this.getErrors(this.errors);
            }
            
            Swal.fire({
              title: "Aviso",
              html: `<span style="font-weight: bold; color:red">${messageError}</span>`,
              icon: "error",
              confirmButtonColor: '#FEB72B',
            });
          })
        }
      } else {
        this.isLoading = false;
        Swal.fire({
          title: "Aviso",
          text: this.errors,
          icon: "warning",
          confirmButtonColor: '#FEB72B',
        });
      }
    },
    async showValidateLastname(result){
      if(result){
        if(this.paternal==""){
          this.focusInputPaterno();
        }else{
          if(this.maternal==""){
            this.focusInputMaterno();
          }
        }
      }
      if(!result){
        if(this.paternal=="" && this.maternal == ""){
          await Swal.fire({
            title: "Aviso",
            text: "Se debe capturar por lo menos un apellido",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          });
          this.focusInputPaterno();
        }
        else{
          this.isValidateLastname = true;
        }
      }
    },
    async validateLastname(){
      this.isLoading=false;
      let lastname= this.paternal == "" ? "Apellido paterno" : "Apellido materno";
      await Swal.fire({
        title: "Aviso",
        text: `Usted no ingresó ${lastname}. ¿Cuenta con él?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: '#FEB72B',
        cancelButtonColor: '#FEB72B',
        confirmButtonText: "Si, Quiero registrarlo",
        cancelButtonText: "No, Continuar con registro"
      }).then((result) => {
            this.showValidateLastname(result.value);
          }
      );
    },
    async validateFilds(){
      this.errors = [];

      if(this.paternal == "" || this.maternal =="" ){
        await this.validateLastname();
        return this.isValidateLastname;
      }

      if(this.names == "" || this.email == "" || this.password == "" || this.confirmPassword == ""){
        this.errors.push("Faltan datos por llenar");
        return false;
      }
      
      if (!this.validateEmail(this.email)) {
        this.errors.push(
            "El correo capturado no es correcto. Favor de verificar el formato"
        );
        return false;
      }
      
      if (this.password.includes(" ") == true || this.confirmPassword.includes(" ") == true){
        this.errors.push("La contraseña no puede contener espacios");
        return false;
      }

      if (this.password != this.confirmPassword) {
        this.errors.push("Las contraseñas son diferentes");
        return false;
      }
      return true;
    },
    async showMessage(){
      if(this.isDataCorrect){
        await Swal.fire({
          title: "Aviso",
          text: "No podemos validar su rfc con los datos que ingresó, puede solicitar apoyo para su registro comunicándose" +
              " a las oficinas de FONCABSA al número: (644) 416-4346",
          icon: "warning",
          confirmButtonColor: '#FEB72B',
        });
      }
      else{
        this.focusInputPaterno();
      }
    },
    checkRegistrationPage(name_reference, reference){
      if(!["member_analyst","loan_analyst","enroller","administrator","supervisor"].includes(name_reference)){
        // window.location.href = "/registro?reference="+reference
        this.$router.push({path:`registro?reference=${reference}`});
      }
    }
  },
};
</script>

<style scoped>
.btn-submit {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: #FFCC00;
    border: 1px solid #FFCC00;
    font-family: Roboto;
    font-weight: 400;
    width: 100%;
    height: 50px;
    color: white;
    transition: all 300ms;
    width: 100%;
}
.center {
  padding: 00px 0;
  border: 3px solid #ffffff;
  text-align: center;
}
@media (min-width: 766px) {
  .collapse.dont-collapse-sm {
    display: block;
    height: auto !important;
    visibility: visible;
  }
}
.button_popups{
      border-top-left-radius: 15px !important;
      border-top-right-radius: 15px !important;
      border-bottom-left-radius: 15px !important;
      border-bottom-right-radius: 15px !important;
      background-color: #FEB72B !important;
      width: 250px !important;
      height: 30px !important;
      padding-top: 0% !important;
  }
button {outline: none !important;}
</style>

